<template>
  <div class="auth-wrapper auth-v2">
    <b-row
      class="auth-inner m-0"
      style="direction: ltr;"
    >

      <!-- Brand logo-->

      <!-- /Brand logo-->


      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-0"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-0">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="auth-bg"
      >
        <b-row>
          <b-col sm="12">
          <span class="brand-logo">
          <b-img
              :src="appLogoImage"
              alt="logo"
          />
        </span>
          </b-col>
        </b-row>

        <b-row class="d-flex align-items-center auth-bg px-2 p-lg-5 form-auth">
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >

            ورود
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t('Sign in Description') }}
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <div
              class="auth-login-form mt-2"
            >
              <!-- email -->
              <b-form-group
                :label="$t('Mobile')"
                label-for="login-mobile"
              >
                <validation-provider
                  #default="{ errors }"
                  name="mobile"
                  rules="required"
                >
                  <b-form-input
                      maxlength="11"
                    id="login-mobile"
                    v-model="user.mobile"
                    style="direction: ltr;"
                    :state="errors.length > 0 ? false:null"
                    name="mobile"
                    placeholder="09121234567"
                    @keyup="mobileChangeEn($event)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                  @click="formValidation"
                type="submit"
                variant="success"
                block
                :disabled="loading"
              ><b-spinner variant="outline-primary" small  v-if="loading"></b-spinner>
                {{ $t('Sign in') }}
              </b-button>
            </div>
          </validation-observer>

        </b-col>
          </b-row>
        <b-row>
          <b-col>
          <!-- checkbox -->
          <b-form-group>

            <b-link
                @click="showModal()"
            >
              سیاست حفظ حریم خصوصی
            </b-link>
          </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    <!-- /Login-->
    </b-row>

    <b-modal
        ref="modal-siasat"
        cancel-variant="outline-secondary"
        ok-title="ذخیره"
        cancel-title="بستن"
        centered
        title="سیاست حفظ حریم خصوصی"
    >
      <p style="text-align: justify;text-justify: inter-word;">
        در صورت استفاده از ایده‌یار با سیاست‌های آن موافقت می‌کنید. ما تنها اطلاعاتی را جمع‌آوری می‌کنیم که با آن بتوانیم خدمات بهتری ارائه دهیم و حساب کاربری شما را در برنامه بهبود دهیم. ما از اطلاعات شخصی شما استفاده‌ دیگری نخواهیم کرد.

        جمع آوری و استفاده از اطلاعات
        برای تجربه بهتر، زمانی که از سرویس‌های ما استفاده می‌کنید ممکن است اطلاعاتی را درخواست کنیم که توسط شما قابل تنظیم است. اطلاعات شما در دستگاه شما حفظ شده و به هیچ وجه توسط ما جمع‌آوری نمی‌شود.ما با استفاده از اطلاعات هویتی شما که شامل نام و نام خانوادگی و شماره همراه شما است یک پروفایل برای شما آماده میشود و اطلاعات شما در این پروفایل برای ارتباطات بعدی حفظ و نگهداری میشود.
      </p>
      <template #modal-footer="">
        <b-button
            size="sm"
            variant="danger"
            @click="hideModal()"
        >
          بستن
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { $themeConfig } from '@themeConfig'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BSpinner,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Vue from 'vue'
import store from '@/store/index'
import User from '@/models/user'
import { functions } from '@/functions'

Vue.prototype.$functions = functions

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      checked: true,
      user: new User('', '', '', '', ''),
      status: '',
      password: '',
      sideImg: require('@/assets/images/pages/arrow-down.png'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      loading: false,
    }
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/arrow-down.png')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  mounted() {
    const { isRTL } = $themeConfig.layout
    const formGroup = document.getElementsByClassName('form-auth')
    formGroup[0].style.direction = isRTL ? 'rtl' : 'ltr'
  },
  methods: {
    showModal(){
      this.$refs['modal-siasat'].show()
    },
    formValidation() {
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          if (this.user.mobile) {
            this.loading = true
            // eslint-disable-next-line radix
            const data = { countrycode: '+98', mobileNo: parseInt(this.user.mobile) , type: 'login' }
            this.$store.dispatch('auth/login', data).then(
              () => {
                this.loading = false
                Vue.prototype.$mobile = this.user.mobile
                Vue.prototype.$mobileR = this.user.mobile
                this.$router.push('/register-verify')
              }, error => {
                this.loading = false
                this.message = error.toString()
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'خطای ورود',
                    text: this.$t(this.message),
                    icon: 'XIcon',
                    variant: 'danger',
                  },
                })
              },
            )
          }
        }
      })
    },
    mobileChangeEn(event) {
      this.user.mobile = this.$functions.fixNumbers(event.target.value)
    },
  },

}
</script>

<style lang="scss">
@import '../../@core/scss/vue/pages/page-auth.scss';
.brand-logo img{
  width: 70px;
}
.auth-wrapper .auth-bg{
  background-color: #ebedf2;
  color: #000;
}
.auth-wrapper label,.auth-wrapper span,.auth-wrapper h2,.auth-wrapper .brand-text{
  color: #000 !important;
}
</style>
